import React, { Component } from 'react';

import axios from 'axios';
import Header from '../directives/header'
import Footer from '../directives/footer'
import Cookies from 'js-cookie';
import config from '../config/config'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import DeviceDetector from "device-detector-js";

const headers = {
  'Content-Type': 'application/json'
};

export default class privacy_policy extends Component {

  constructor(props) {
    super(props);
    this.state = {
      webcontentlist: {}
    };

  }


  componentDidMount() {
    this.getWebContentData()
  }

  async getWebContentData() {

    let headers = {
      'Authorization': this.loginData?.Token,
      'Content-Type': 'application/json'
    }
    axios.get(`${config.apiUrl}/getwebcontent`, {}, { headers: headers })
      .then(response => {
        if (response.data.success === true) {
          this.setState({
            webcontentlist: response.data.response
          })
        }

        else if (response.data.success === false) {

        }
      })

      .catch(err => {
      })

  }

  createMarkup() {
    return { __html: this.state.webcontentlist.privacy_policy };
  }


  render() {
    return (
      <>
        <Header />
        <div class="container">
          <div class="row">
            <h1 class="History mb-3" style={{ marginTop: '70px' }}>Privacy Policy</h1>
            {/* <h5 class="mt-3">[Last Update: 2020.12.04]</h5> */}
            <div class="privacy-para pt-5">
              <div dangerouslySetInnerHTML={this.createMarkup()} />;
              {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc cursus nisl nec euismod mattis. Praesent augue nulla, finibus facilisis risus venenatis, vulputate bibendum diam. Nulla rutrum erat ac diam euismod, in condimentum tortor pharetra. Donec at justo in mauris condimentum luctus ut nec dolor. Praesent ut imperdiet mi. Ut gravida lacus id quam malesuada volutpat non ut quam. Nunc posuere euismod mauris non efficitur. Etiam rhoncus diam ac volutpat varius. Morbi condimentum dolor iaculis est dapibus, id volutpat augue facilisis. Morbi venenatis enim enim, in tempor ante blandit eu. Vivamus sed velit odio. Vivamus vel bibendum nisi.</p>
<br/>
               <p> Nullam rhoncus neque vel ante mollis ultricies. Nam elementum velit vel nibh luctus, nec finibus libero faucibus. In porttitor sit amet velit sed consectetur. Suspendisse eleifend nunc a elit sagittis porta. Curabitur quis sagittis diam. Mauris augue lacus, dignissim quis nisi nec, tempor venenatis est. Fusce elementum leo in erat egestas placerat.</p>
               <br/>
               <p> Pellentesque eu vestibulum turpis. Nunc sollicitudin consectetur facilisis. Ut vitae lacinia est, id convallis enim. Sed vel nulla posuere, accumsan metus vel, dapibus mi. Nunc laoreet velit quis dui ullamcorper sollicitudin. Pellentesque auctor ultricies neque eget commodo. Aenean aliquam ornare fringilla. Quisque cursus turpis est. Vivamus accumsan arcu vitae est pulvinar cursus. Phasellus et erat massa. Maecenas augue arcu, condimentum ac maximus sodales, pellentesque ac orci. Curabitur ut elementum sapien, a placerat velit. Morbi tincidunt lectus a lacus elementum pellentesque et a magna.</p>
               <br/>
               <p> Nam mollis, dolor ac venenatis placerat, elit dolor egestas urna, vel condimentum tortor nibh a velit. Cras ac hendrerit nulla. Fusce mollis sapien a ullamcorper pulvinar. Suspendisse potenti. Curabitur et sollicitudin augue, quis vulputate neque. Aliquam tristique nibh ut vehicula molestie. In lobortis rhoncus lectus eu ornare. Proin facilisis, enim ut maximus elementum, ex quam tristique felis, nec varius purus sapien in urna. Vestibulum ullamcorper lorem ut tristique tristique. Nam congue suscipit malesuada. Phasellus lacus nunc, vulputate a purus a, viverra pellentesque odio. Donec fermentum, velit eu blandit egestas, velit arcu bibendum neque, vitae pellentesque enim eros eu turpis. Sed ut pharetra odio, ac ullamcorper lectus. Duis non nunc non mauris fringilla sollicitudin.</p>
               <br/>
                <p>Nunc vestibulum eros urna, sed maximus purus commodo eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam rhoncus auctor rhoncus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Integer lacus elit, dapibus vitae arcu nec, pretium cursus ex. Phasellus posuere tortor justo, vitae lobortis justo mollis eu. Donec luctus malesuada orci, eu aliquet urna lobortis id. Donec mattis tortor quis lorem sodales, non tempus lacus gravida.</p>
                <br/><br/><br/> */}
            </div>
          </div>

        </div>
        <Footer />

      </>
    )
  }
}