
import React, { Component } from 'react';

import axios from 'axios';
import Header from '../directives/header'
import Footer from '../directives/footer'
import Cookies from 'js-cookie';
import config from '../config/config'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import DeviceDetector from "device-detector-js";

const headers = {
    'Content-Type': 'application/json'
};

export default class terms_condition extends Component {

    constructor(props) {
        super(props);
        this.state = {
            webcontentlist:{}
        };

    }

    componentDidMount() {
        this.getWebContentData()
    }

    async getWebContentData() {

        let headers = {
            'Authorization': this.loginData?.Token,
            'Content-Type': 'application/json'
        }
        axios.get(`${config.apiUrl}/getwebcontent`, {}, { headers: headers })
            .then(response => {
                if (response.data.success === true) {
                    this.setState({
                        webcontentlist: response.data.response
                    })
                }

                else if (response.data.success === false) {

                }
            })

            .catch(err => {
            })

    }

      createMarkup() {
        return {__html: this.state.webcontentlist.terms_condition};
      }

    render() {
        // console.log( this.createMarkup())
        return (



            <>



                <Header />
                <div class="container">
                    <div class="row">
                        <h1 class="History mb-3" style={{ marginTop: '50px' }}>Terms & Condition</h1>
                        {/* <h5 class="mt-3">Last revised: 30 March, 2021</h5> */}
                        <div class="privacy-para pt-5">
                        <div dangerouslySetInnerHTML={this.createMarkup()} />;
                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc cursus nisl nec euismod mattis. Praesent augue nulla, finibus facilisis risus venenatis, vulputate bibendum diam. Nulla rutrum erat ac diam euismod, in condimentum tortor pharetra. Donec at justo in mauris condimentum luctus ut nec dolor. Praesent ut imperdiet mi. Ut gravida lacus id quam malesuada volutpat non ut quam. Nunc posuere euismod mauris non efficitur. Etiam rhoncus diam ac volutpat varius. Morbi condimentum dolor iaculis est dapibus, id volutpat augue facilisis. Morbi venenatis enim enim, in tempor ante blandit eu. Vivamus sed velit odio. Vivamus vel bibendum nisi.</p>
<br/>
               <p> Nullam rhoncus neque vel ante mollis ultricies. Nam elementum velit vel nibh luctus, nec finibus libero faucibus. In porttitor sit amet velit sed consectetur. Suspendisse eleifend nunc a elit sagittis porta. Curabitur quis sagittis diam. Mauris augue lacus, dignissim quis nisi nec, tempor venenatis est. Fusce elementum leo in erat egestas placerat.</p>
               <br/>
               <p> Pellentesque eu vestibulum turpis. Nunc sollicitudin consectetur facilisis. Ut vitae lacinia est, id convallis enim. Sed vel nulla posuere, accumsan metus vel, dapibus mi. Nunc laoreet velit quis dui ullamcorper sollicitudin. Pellentesque auctor ultricies neque eget commodo. Aenean aliquam ornare fringilla. Quisque cursus turpis est. Vivamus accumsan arcu vitae est pulvinar cursus. Phasellus et erat massa. Maecenas augue arcu, condimentum ac maximus sodales, pellentesque ac orci. Curabitur ut elementum sapien, a placerat velit. Morbi tincidunt lectus a lacus elementum pellentesque et a magna.</p>
               <br/>
               <p> Nam mollis, dolor ac venenatis placerat, elit dolor egestas urna, vel condimentum tortor nibh a velit. Cras ac hendrerit nulla. Fusce mollis sapien a ullamcorper pulvinar. Suspendisse potenti. Curabitur et sollicitudin augue, quis vulputate neque. Aliquam tristique nibh ut vehicula molestie. In lobortis rhoncus lectus eu ornare. Proin facilisis, enim ut maximus elementum, ex quam tristique felis, nec varius purus sapien in urna. Vestibulum ullamcorper lorem ut tristique tristique. Nam congue suscipit malesuada. Phasellus lacus nunc, vulputate a purus a, viverra pellentesque odio. Donec fermentum, velit eu blandit egestas, velit arcu bibendum neque, vitae pellentesque enim eros eu turpis. Sed ut pharetra odio, ac ullamcorper lectus. Duis non nunc non mauris fringilla sollicitudin.</p>
               <br/>
                <p>Nunc vestibulum eros urna, sed maximus purus commodo eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam rhoncus auctor rhoncus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Integer lacus elit, dapibus vitae arcu nec, pretium cursus ex. Phasellus posuere tortor justo, vitae lobortis justo mollis eu. Donec luctus malesuada orci, eu aliquet urna lobortis id. Donec mattis tortor quis lorem sodales, non tempus lacus gravida.</p>
                <br/><br/><br/> */}
                           
                            {/* <p>These Crypto GPK Terms of Use is entered into between you (hereinafter referred to as “you” or “your”) and Crypto GPK operators (as defined below). By accessing, downloading, using or clicking on “I agree” to accept any Crypto GPK Services (as defined below) provided by Crypto GPK (as defined below), you agree that you have read, understood and accepted all of the terms and conditions stipulated in these Terms of Use (hereinafter referred to as “these Terms”) as well as our Privacy Policy at   https://www.cryptogpk.com/en/privacy. In addition, when using some features of the Services, you may be subject to specific additional terms and conditions applicable to those features.</p>
                            <p>Please read the terms carefully as they govern your use of Crypto GPK Services.THESE TERMS CONTAIN IMPORTANT PROVISIONS INCLUDING AN ARBITRATION PROVISION THAT REQUIRES ALL CLAIMS TO BE RESOLVED BY WAY OF LEGALLY BINDING ARBITRATION. The terms of the arbitration provision are set forth in Article 10, “Resolving Disputes: Forum, Arbitration, Class Action Waiver”, hereunder. As with any asset, the values of Digital Currencies (as defined below) may fluctuate significantly and there is a substantial risk of economic losses when purchasing, selling, holding or investing in Digital Currencies and their derivatives.BY MAKING USE OF Crypto GPK SERVICES, YOU ACKNOWLEDGE AND AGREE THAT: (1) YOU ARE AWARE OF THE RISKS ASSOCIATED WITH TRANSACTIONS OF DIGITAL CURRENCIES AND THEIR DERIVATIVES; (2) YOU SHALL ASSUME ALL RISKS RELATED TO THE USE OF Crypto GPK SERVICES AND TRANSACTIONS OF DIGITAL CURRENCIES AND THEIR DERIVATIVES; AND (3) Crypto GPK SHALL NOT BE LIABLE FOR ANY SUCH RISKS OR ADVERSE OUTCOMES.</p>
                            <p>By accessing, using or attempting to use Crypto GPK Services in any capacity, you acknowledge that you accept and agree to be bound by these Terms. If you do not agree, do not access Crypto GPK or utilize Crypto GPK services.</p>

                            <h5 class="mt-2">I. Definitions</h5>
                            <p>1. Crypto GPK refers to an ecosystem comprising Crypto GPK websites (whose domain names include but are not limited to  https://www.Crypto GPK.com), mobile applications, clients, applets and other applications that are developed to offer Crypto GPK Services, and includes independently-operated platforms, websites and clients within the ecosystem (e.g. Crypto GPK’s Open Platform, Crypto GPK Launchpad, Crypto GPK Labs, Crypto GPK Charity, Crypto GPK DEX, Crypto GPK X, JEX, Trust Wallet, and fiat gateways). In case of any inconsistency between relevant terms of use of the above platforms and the contents of these Terms, the respective applicable terms of such platforms shall prevail.</p>


                            <p>2. Crypto GPK Operators refer to all parties that run Crypto GPK, including but not limited to legal persons, unincorporated organizations and teams that provide Crypto GPK Services and are responsible for such services. For convenience, unless otherwise stated, references to “Crypto GPK” and “we” in these Terms specifically mean Crypto GPK Operators.UNDER THESE TERMS, Crypto GPK OPERATORS MAY CHANGE AS Crypto GPK’S BUSINESS ADJUSTS, IN WHICH CASE, THE CHANGED OPERATORS SHALL PERFORM THEIR OBLIGATIONS UNDER THESE TERMS WITH YOU AND PROVIDE SERVICES TO YOU, AND SUCH CHANGE DOES NOT AFFECT YOUR RIGHTS AND INTERESTS UNDER THESE TERMS. ADDITIONALLY, THE SCOPE OF Crypto GPK OPERATORS MAY BE EXPANDED DUE TO THE PROVISION OF NEW Crypto GPK SERVICES, IN WHICH CASE, IF YOU CONTINUE TO USE Crypto GPK SERVICES, IT IS DEEMED THAT YOU HAVE AGREED TO JOINTLY EXECUTE THESE TERMS WITH THE NEWLY ADDED Crypto GPK OPERATORS. IN CASE OF A DISPUTE, YOU SHALL DETERMINE THE ENTITIES BY WHICH THESE TERMS ARE PERFORMED WITH YOU AND THE COUNTERPARTIES OF THE DISPUTE, DEPENDING ON THE SPECIFIC SERVICES YOU USE AND THE PARTICULAR ACTIONS THAT AFFECT YOUR RIGHTS OR INTERESTS.</p>



                            <p>3. Crypto GPK Services refer to various services provided to you by Crypto GPK that are based on Internet and/or blockchain technologies and offered via Crypto GPK websites, mobile applications, clients and other forms (including new ones enabled by future technological development). Crypto GPK Services include but are not limited to such Crypto GPK ecosystem components as Digital Asset Trading Platforms, the financing sector, Crypto GPK Labs, Crypto GPK Academy, Crypto GPK Charity, Crypto GPK Info, Crypto GPK Launchpad, Crypto GPK Research, Crypto GPK Chain, Crypto GPK X, Crypto GPK Fiat Gateway, existing services offered by Trust Wallet and novel services to be provided by Crypto GPK.</p>

                            <p>4. Crypto GPK Platform Rules refer to all rules, interpretations, announcements, statements, letters of consent and other contents that have been and will be subsequently released by Crypto GPK, as well as all regulations, implementation rules, product process descriptions, and announcements published in the Help Center or within products or service processes.</p>

                            <p>5. Users refer to all individuals, institutions or organizations that access, download or use Crypto GPK or Crypto GPK Services and who meet the criteria and conditions stipulated by Crypto GPK. If there exist other agreements for such entities as developers, distributors, market makers, and Digital Currencies exchanges, such agreements shall be followed.</p>

                            <p>6. Digital Currencies refer to encrypted or digital tokens or cryptocurrencies with a certain value that are based on blockchain and cryptography technologies and are issued and managed in a decentralized form.</p> */}
                        </div>
                    </div>

                </div>


                <Footer />

            </>
        )
    }
}