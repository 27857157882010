import React, { Component } from 'react';

import axios from 'axios';
import Header from '../directives/header'
import Footer from '../directives/footer'
import Cookies from 'js-cookie';
import config from '../config/config'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import DeviceDetector from "device-detector-js";

const headers = {
    'Content-Type': 'application/json'
};

export default class about_us extends Component {

    constructor(props) {
        super(props);
        this.state = {
            webcontentlist: {}
        };

    }


    componentDidMount() {
        this.getWebContentData()
    }

    async getWebContentData() {

        let headers = {
            'Authorization': this.loginData?.Token,
            'Content-Type': 'application/json'
        }
        axios.get(`${config.apiUrl}/getwebcontent`, {}, { headers: headers })
            .then(response => {
                if (response.data.success === true) {
                    this.setState({
                        webcontentlist: response.data.response
                    })
                }

                else if (response.data.success === false) {

                }
            })

            .catch(err => {
            })

    }

    createMarkup() {
        return { __html: this.state.webcontentlist.about };
    }


    render() {
        return (



            <>
                {/* 

                <div className="container">

                    <div className="row"> */}

                <Header />
                <div className="about_as">
                    <img src="images/bg/slider-11.png" class="about-banner" />

                    <div class="container">
                        <div class="row">

                            <div class="banner-content" style={{ position: 'absolute', top: '40%', left: '0' }}>
                                <div className='col-lg-12'>
                                    <h1>About Best In Coins</h1>
                                    <p class="mt-4">Beyond operating the world's leading cryptocurrency exchange, <br />Best In Coins spans an entire ecosystem.</p>
                                </div>
                            </div>
                        </div>
                        <div class="privacy-para pt-5">
                            <div dangerouslySetInnerHTML={this.createMarkup()} />;
                            {/* <p>PlatinX Exchange is a crypto asset Trading platform with the vision to accelerate the adoption of digital assets and help grow the crypto ecosystem. PlatinX Exchange allows users to trade digital assets on the most liquid and secure app/website through a simple interface and decentralized, trustworthy and highly liquid trading platform.</p>
                            <br />
                            <p> There are many exchanges offering their services worldwide however to utilize the vision of a truly decentralized currency, there is lack of quality trading platforms that can give you above-par services, true anonymity, lightning-fast transaction speed with simple user-interface.</p>
                            <br />
                            <p> This gap in the services of crypto trading led to the birth of PlatinX Exchange with the aim to help crypto participants grow their saving and enjoy crypto participation through a highly liquid platform. </p>
                            <br />
                            <p> PlatinX Exchange is backed by a team of crypto experts, blockchain developers and programmers who are enthusiastic about the crypto future and have worked hard to provide a transparent, unbiased and intermediary free – P2P trading platform. When it comes to exchanges and dealing with someone’s money then transparency and decentralization is the best way forward. </p>
                            <br />
                            <p>PlatinX Exchange will strive to be the best P2P crypto exchange of the world with excellent, world-class services, deep global liquidity, Institutional grade security & custody as well as highly economical trading fees. Some of the additional benefits for users of PlatinX Exchange would be Stake, save & earn as well as priority token allocation in discounted token sales.</p>
                            <br /><br /><br /> */}

                            {/* <p>These Crypto GPK Terms of Use is entered into between you (hereinafter referred to as “you” or “your”) and Crypto GPK operators (as defined below). By accessing, downloading, using or clicking on “I agree” to accept any Crypto GPK Services (as defined below) provided by Crypto GPK (as defined below), you agree that you have read, understood and accepted all of the terms and conditions stipulated in these Terms of Use (hereinafter referred to as “these Terms”) as well as our Privacy Policy at   https://www.cryptogpk.com/en/privacy. In addition, when using some features of the Services, you may be subject to specific additional terms and conditions applicable to those features.</p>
               <p>Please read the terms carefully as they govern your use of Crypto GPK Services.THESE TERMS CONTAIN IMPORTANT PROVISIONS INCLUDING AN ARBITRATION PROVISION THAT REQUIRES ALL CLAIMS TO BE RESOLVED BY WAY OF LEGALLY BINDING ARBITRATION. The terms of the arbitration provision are set forth in Article 10, “Resolving Disputes: Forum, Arbitration, Class Action Waiver”, hereunder. As with any asset, the values of Digital Currencies (as defined below) may fluctuate significantly and there is a substantial risk of economic losses when purchasing, selling, holding or investing in Digital Currencies and their derivatives.BY MAKING USE OF Crypto GPK SERVICES, YOU ACKNOWLEDGE AND AGREE THAT: (1) YOU ARE AWARE OF THE RISKS ASSOCIATED WITH TRANSACTIONS OF DIGITAL CURRENCIES AND THEIR DERIVATIVES; (2) YOU SHALL ASSUME ALL RISKS RELATED TO THE USE OF Crypto GPK SERVICES AND TRANSACTIONS OF DIGITAL CURRENCIES AND THEIR DERIVATIVES; AND (3) Crypto GPK SHALL NOT BE LIABLE FOR ANY SUCH RISKS OR ADVERSE OUTCOMES.</p>
               <p>By accessing, using or attempting to use Crypto GPK Services in any capacity, you acknowledge that you accept and agree to be bound by these Terms. If you do not agree, do not access Crypto GPK or utilize Crypto GPK services.</p>

               <h5 class="mt-2">I. Definitions</h5>
               <p>1. Crypto GPK refers to an ecosystem comprising Crypto GPK websites (whose domain names include but are not limited to  https://www.Crypto GPK.com), mobile applications, clients, applets and other applications that are developed to offer Crypto GPK Services, and includes independently-operated platforms, websites and clients within the ecosystem (e.g. Crypto GPK’s Open Platform, Crypto GPK Launchpad, Crypto GPK Labs, Crypto GPK Charity, Crypto GPK DEX, Crypto GPK X, JEX, Trust Wallet, and fiat gateways). In case of any inconsistency between relevant terms of use of the above platforms and the contents of these Terms, the respective applicable terms of such platforms shall prevail.</p>

               
               <p>2. Crypto GPK Operators refer to all parties that run Crypto GPK, including but not limited to legal persons, unincorporated organizations and teams that provide Crypto GPK Services and are responsible for such services. For convenience, unless otherwise stated, references to “Crypto GPK” and “we” in these Terms specifically mean Crypto GPK Operators.UNDER THESE TERMS, Crypto GPK OPERATORS MAY CHANGE AS Crypto GPK’S BUSINESS ADJUSTS, IN WHICH CASE, THE CHANGED OPERATORS SHALL PERFORM THEIR OBLIGATIONS UNDER THESE TERMS WITH YOU AND PROVIDE SERVICES TO YOU, AND SUCH CHANGE DOES NOT AFFECT YOUR RIGHTS AND INTERESTS UNDER THESE TERMS. ADDITIONALLY, THE SCOPE OF Crypto GPK OPERATORS MAY BE EXPANDED DUE TO THE PROVISION OF NEW Crypto GPK SERVICES, IN WHICH CASE, IF YOU CONTINUE TO USE Crypto GPK SERVICES, IT IS DEEMED THAT YOU HAVE AGREED TO JOINTLY EXECUTE THESE TERMS WITH THE NEWLY ADDED Crypto GPK OPERATORS. IN CASE OF A DISPUTE, YOU SHALL DETERMINE THE ENTITIES BY WHICH THESE TERMS ARE PERFORMED WITH YOU AND THE COUNTERPARTIES OF THE DISPUTE, DEPENDING ON THE SPECIFIC SERVICES YOU USE AND THE PARTICULAR ACTIONS THAT AFFECT YOUR RIGHTS OR INTERESTS.</p>

               
               
               <p>3. Crypto GPK Services refer to various services provided to you by Crypto GPK that are based on Internet and/or blockchain technologies and offered via Crypto GPK websites, mobile applications, clients and other forms (including new ones enabled by future technological development). Crypto GPK Services include but are not limited to such Crypto GPK ecosystem components as Digital Asset Trading Platforms, the financing sector, Crypto GPK Labs, Crypto GPK Academy, Crypto GPK Charity, Crypto GPK Info, Crypto GPK Launchpad, Crypto GPK Research, Crypto GPK Chain, Crypto GPK X, Crypto GPK Fiat Gateway, existing services offered by Trust Wallet and novel services to be provided by Crypto GPK.</p>
               
               <p>4. Crypto GPK Platform Rules refer to all rules, interpretations, announcements, statements, letters of consent and other contents that have been and will be subsequently released by Crypto GPK, as well as all regulations, implementation rules, product process descriptions, and announcements published in the Help Center or within products or service processes.</p>
               
               <p>5. Users refer to all individuals, institutions or organizations that access, download or use Crypto GPK or Crypto GPK Services and who meet the criteria and conditions stipulated by Crypto GPK. If there exist other agreements for such entities as developers, distributors, market makers, and Digital Currencies exchanges, such agreements shall be followed.</p>
               
               <p>6. Digital Currencies refer to encrypted or digital tokens or cryptocurrencies with a certain value that are based on blockchain and cryptography technologies and are issued and managed in a decentralized form.</p> */}
                        </div>

                    </div>
                    {/* <section>
                        <div class="container about_as">
                            <div class="about-section">
                                <div class="row">
                                    <div class="col-12 col-lg-6 col-xl-6 col-md-6 ">
                                        <h2>Use. Trade. Store</h2>
                                       <div className="row">
                                           <div className="col-12 col-md-12">
                                           <p class="mt-5">BNB is the native asset on btcpari Chain. BNB has multiple forms of utility and powers the btcpari Ecosystem as its underlying gas.</p>
                                        <a class=" More-btn" href="">More</a>
                                           </div>
                                       </div>
                                    </div>
                                    <div class="col-12 col-lg-6 col-xl-6 col-md-6  about_as">
                                        <img src="images/diamond.png" class="about-diamond" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> */}

                    {/* <section>
                        <div class="container about_as">
                            <div class="about-section">
                                <div class="row">
                                    <div class="col-12 col-md-6 about_as">
                                        <img src="https://www.binance.com/_next/static/images/build-f2f5d64e22b1d1c75a934a7c74debbb8.svg" class="about-diamonds" />
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <h2>Global Freedom</h2>
                                        <p class="mt-5">Our vision is to increase the freedom of money globally. We believe that by spreading this freedom, we can significantly improve lives around the world</p>
                                        <a class=" More-btn" href="">Vision</a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section> */}

                    {/* <section className="">
                        <div class="container about_as">
                            <div class="about-section">
                                <div class="row">
                                    <div class="col-12 col-md-6">
                                        <h2>Building Infrastructure</h2>
                                        <p class="mt-5">Our mission is to be the infrastructure services provider for the blockchain ecosystem.</p>
                                        <a class=" More-btn" href="">Mission</a>
                                    </div>
                                    <div class="col-12 col-md-6 about_as">
                                        <img src="https://www.binance.com/_next/static/images/freedom-7ac85a627eb0b730a074879ac8a57977.svg" class="about-diamonda" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> */}
                </div>
                <Footer />

            </>
        )
    }
}