import React, { Component } from 'react';
import axios from 'axios';
import config from './config/config'
import Header from './directives/header'
import Footer from './directives/footer'
import Countdown from 'react-countdown';
import Cookies from 'js-cookie';
import ReactPlayer from 'react-player'
import { Player } from 'video-react';
import { Link, Redirect } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

const headers = {
    'Content-Type': 'application/json'
};

export default class extends Component {

    constructor(props) {
        super(props)

        this.loginData = (!Cookies.get('loginSuccess')) ? [] : JSON.parse(Cookies.get('loginSuccess'));
        // const { match: { params } } = this.props;
        this.loginId = (!this.loginData.data) ? 0 : this.loginData.data.id;
        this.href = window.location.href;
        this.coin_symbol = this.href.substring(this.href.lastIndexOf('/') + 1);

        this.state = {
            headerSeacrhCoin: ['BTCUSDT', 'ETHUSDT', 'BCHUSDT', 'BNBUSDT'],
            mainMarketData: [],
            AllMainMarketData: [],
            my_pair_list: [],
            email: '',
            notification_list: []
        }
        this.mainMarketDataFun = this.mainMarketDataFun.bind(this)
        this.pairListAPI = this.pairListAPI.bind(this);
        this.onChange = this.onChange.bind(this);


    }


    componentDidMount() {
        this.mainMarketDataFun();
        this.pairListAPI();
        this.notificationDetails()
    }


    async notificationDetails() {

        await axios.get(`${config.apiUrl}/getusernotification`, {}, { headers })
            .then(result => {

                if (result.data.success === true) {
                    this.setState({
                        notification_list: result.data.response
                    })
                }

                else if (result.data.success === false) {

                }
            })

            .catch(err => {
                if (err == 'Error: Request failed with status code 403') {
                    toast.error('Session expired please re-login')
                }
            })
    }




    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    gotSignup() {
        Cookies.set('email', this.state.email,{ secure: config.Secure,HttpOnly:config.HttpOnly});
        if (this.state.email) {
            if (this.loginId) {
                window.location.href = `${config.baseUrl}Exchange`;
            } else {
                window.location.href = `${config.baseUrl}signup`;
            }
        }
    }
    async pairListAPI(id) {

        if (id === undefined) {
            this.searchData = ''
        }
        else {
            this.searchData = id
        }
        await axios({
            method: 'post',
            url: `${config.apiUrl}/pairlist` + '?nocache=' + new Date().getTime(),
            headers: { "Authorization": this.loginData?.Token },
            data: { search: this.searchData }
        })
            .then(async result => {
                if (result.data.success === true) {
                    this.setState({
                        my_pair_list: result.data.response
                    })
                    let MainArr = [];
                    // alert(this.state.my_pair_list.length)
                    for (var i = 0; i < this.state.my_pair_list.length; i++) {
                        var symbol = this.state.my_pair_list[i].left_symbol + this.state.my_pair_list[i].right_symbol;
                        var dataGet = await fetch('https://api.binance.com/api/v3/ticker/24hr?symbol=' + symbol);
                        var resData = await dataGet.json();
                        MainArr[i] = resData;
                        MainArr[i].left_symbol = this.state.my_pair_list[i].left_symbol;
                        MainArr[i].right_symbol = this.state.my_pair_list[i].right_symbol;
                    }
                    this.setState({
                        AllMainMarketData: MainArr
                    });
                }


            })

            .catch(err => {
                if (err == 'Error: Request failed with status code 403') {
                    toast.error('Session expired please re-login')
                }
                // toast.error(err.result?.data?.msg, {
                //     position: toast.POSITION.TOP_CENTER
                // })


            })
    }

    async mainMarketDataFun() {
        let MainArr = [];
        for (var i = 0; i < this.state.headerSeacrhCoin.length; i++) {
            var data = await fetch('https://api.binance.com/api/v3/ticker/24hr?symbol=' + this.state.headerSeacrhCoin[i]);
            var resData = await data.json();
            MainArr[i] = resData;
        }
        this.setState({
            mainMarketData: MainArr
        });

    }




    render() {
        return (
            <>
                <Header />
                <ToastContainer />
                <div>
                    <div className='video-container'>
                        <div class="overlay"></div>
                        <video id="backgroundVideo" autoPlay muted loop>
                            <source src="videos/awesome-night-sky.mp4" type="video/mp4" />
                        </video>
                    </div>
                </div>


                <div class="site-wrapper pb-5 container">
                    <div class="site-wrapper-inner ">

                        <div class="container pt-5 ">
                            <div class="row">
                                <div class="col-12 col-md-6">
                                    <h2 class="banner_heading mt-5">
                                        Welcome to Best In Coins!  The fastest and safest crypto asset trading platform
                                    </h2>
                                    {/* <a href="#" class="btn-outline-success btn-token">Whitepaper</a> */}
                                </div>
                                <div class="col-12 col-md-6">
                                    <div class="inner cover">
                                        <lottie-player src="https://assets10.lottiefiles.com/packages/lf20_iak8qpw7.json" id="lottie_images" background="transparent" speed={1} style={{ width: '500px', height: '500px' }} loop autoPlay />
                                    </div>
                                </div>
                            </div>
                        </div>


                        <section class="pro-section mt-5">
                            <div class="container">
                                <div class="row">
                                    <div class="pro_images mt-3">
                                        <img src="img/logonew.webp" style={{filter: "invert(1)"}} alt="" class="platform-img" />

                                        {/* <span class="pro-span"><h3>Exchange</h3></span> */}
                                    </div>
                                    <h2 class="mt-2">Now trade Crypto Assets on our new Platform with ease!</h2>
                                    <p>Best In Coins provides superior trading experience to professionals with real-time charts, different crypto assets, trading view tools and indicators</p>
                                </div>
                                <div className='row justify-content-center align-items-center'>
                                    <div class="col-12 col-md-6">
                                        <div class="pro_img">
                                            <img src="img/coin.png" alt="" class="asset_image" />
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <div class="pro_content">
                                            <ul class="unorder-list">
                                                <li><i class="fas fa-check-circle"></i><p className='app_tip'>Unparalleled Ease of Trading</p></li>
                                                <li><i class="fas fa-check-circle"></i><p className='app_tip'>Top notch market and limit transactions safety</p></li>
                                                <li><i class="fas fa-check-circle"></i><p className='app_tip'>Fast Transfer ability</p></li>
                                                <li><i class="fas fa-check-circle"></i><p className='app_tip'>Best Trading View Tools and Indicators</p></li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </section>
                        <section class="mobile-section mt-5">
                            <div class="container">
                                <div class="row">
                                    <div class="pro_images mt-3 mb-5">
                                        <img src="img/logonew.webp" style={{filter: "invert(1)"}} alt="" class="platform-imgs" />

                                    </div>
                                    {/* <h2 class="mt-2">OUR APPLICATION</h2> */}
                                    {/* <p>Our Robust Mobile Application is now In All Market
                                    </p>
                                    <div className='row'>
                                        <div className='col-md-4'></div>
                                        <div className='col-md-2 col-6'>
                                            <a className='' onClick={e => window.open(`https://i.diawi.com/QDYFMf`)}>  <img src="img/app_store_download.png" alt="" className="apple_img" /></a>
                                        </div>

                                        <div className='col-md-2 col-6'>
                                            <a className='' onClick={e => window.open(`${config.imageUrl}Best In Coins.apk`)}>  <img src="img/google_play_download.png" alt="" className="android_img" /></a>
                                        </div>
                                        <div className='col-md-4'></div>
                                    </div> */}
                                    <div class="col-12 col-md-6 ">
                                        <div class="pro_content">
                                            <ul class="unorder-list">
                                                <li><i class="fas fa-check-circle"></i><p className='app_tip'>Easy Sign Up Process</p></li>
                                                <li><i class="fas fa-check-circle"></i><p className='app_tip'>Instantly Buy and Sell Crypto assets</p></li>
                                                <li><i class="fas fa-check-circle"></i><p className='app_tip'>Easy Accessibility to your assets </p></li>
                                                <li><i class="fas fa-check-circle"></i><p className='app_tip'>Fast Wallet Track ability</p></li>
                                                <li><i class="fas fa-check-circle"></i><p className='app_tip'>Lowest Trading Fee</p></li>
                                                <li><i class="fas fa-check-circle"></i><p className='app_tip'>Trade any , Any where</p></li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="col-12 col-md-6">
                                        <div class="pro_img">
                                            <img src="img/desktop-coin.png" alt="" class="asset_image" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <br />
                        <section className="section section-sm pt-5 pb-5">
                            <div className="container">
                                <div className="row ">
                                    <div className="col-sm-12 text-center mt-3">
                                        
                                        <h2><span className="d-inline-block" style={{ maxWidth: '520px' }}>Why&nbsp;<strong>Best In Coins Crypto?</strong></span></h2>
                                        <br /><br />
                                    </div>

                                </div>
                                <div className="row row-30">
                                    <div className="col-lg-6 mb-4">
                                        <div className="col-12 wow fadeInUpSmall">
                                            {/* Link Box*/}
                                            <a className="link-box" >
                                                <img src="images/ultra_secure1.png" className="icon link-box__icon linearicons-mouse-right white-icon" />
                                                <div className="link-box__main">
                                                    <h4>Ultra-secure</h4>
                                                    <p>Where there is security, there is trust. Best In Coins ensures the security of trader's funds and transactions by using multi-layer encryption models with credible technologies.</p>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="col-12 wow fadeInUpSmall" data-wow-delay=".1s">
                                            {/* Link Box*/}
                                            <a className="link-box" >
                                                <img src="images/core-team-icon1.png" className="icon link-box__icon linearicons-mouse-right white-icon" />
                                                <div className="link-box__main">
                                                    <h4>Professional</h4>
                                                    <p>Best In Coins core team consists of block chain experts with more than ten years of crypto industry experience. This makes the entire system is a safe and efficient global trading platform.</p>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mt-4">
                                        <div className="col-12 wow fadeInUpSmall" data-wow-delay=".2s">
                                            {/* Link Box */}
                                            <a className="link-box" id="convenient_link-box" >
                                                <img src="images/convenient1.png" className="icon link-box__icon linearicons-mouse-right white-icon" />
                                                <div className="link-box__main">
                                                    <h4>Convenient</h4>
                                                    <p>The user friendly interface and quick crypto asset conversion facilities help traders enjoy a swift and seamless experience. Fiat currency support makes Best In Coins the most sought after exchange among novice traders.</p>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mt-4">
                                        <div className="col-12 wow fadeInUpSmall" data-wow-delay=".1s">
                                            {/* Link Box*/}
                                            <a className="link-box" >
                                                <img src="images/conformity1.png" className="icon link-box__icon linearicons-mouse-right white-icon" />
                                                <div className="link-box__main">
                                                    <h4>Conformity</h4>
                                                    <p>To provide an authentic experience and expand globally, Best In Coins complies with legal regulations. Also, rigorous resource emergence strategies are employed to maximize trader benefits.</p>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <br />
                        <section className="section section-sm pt-5 pb-5">
                            <div className="container">
                                <div className="row ">
                                    <div className="col-sm-12 text-center mt-3">
                                      
                                        <h2><span className="d-inline-block" style={{ maxWidth: '520px' }}>Trader's <strong>Exclusive Privileges</strong></span></h2>
                                        <br /><br />
                                    </div>
                                    <div className="col-sm-6 col-lg-4 wow fadeIn">
                                        {/* Box Minimal*/}
                                        <article className="box-minimal">
                                            <span className="icon box-minimal__icon linearicons-document"></span>

                                            <h4 className="box-minimal__title" > Instant KYC</h4>
                                            <div className="box-minimal__divider" />
                                            <p>KYC authentication procedures are followed to permit credible traders in the Best In Coins  Crypto market.</p>
                                        </article>
                                    </div>
                                    <div className="col-sm-6 col-lg-4 wow fadeIn" data-wow-delay=".1s">
                                        {/* Box Minimal*/}
                                        <article className="box-minimal">
                                            <span class="icon box-minimal__icon linearicons-wallet"></span>
                                            <h4 className="box-minimal__title">Instant Deposit &amp; Withdrawal</h4>
                                            <div className="box-minimal__divider" />
                                            <p>Link your bank account and straightaway trade and withdraw funds whenever required with comfort.</p>
                                        </article>
                                    </div>
                                    <div className="col-sm-6 col-lg-4 wow fadeIn" data-wow-delay=".2s">
                                        {/* Box Minimal*/}
                                        <article className="box-minimal">
                                            <span class="icon box-minimal__icon linearicons-graph"></span>
                                            <h4 className="box-minimal__title">Lowest Trading Fee</h4>
                                            <div className="box-minimal__divider" />
                                            <p>Best In Coins is the leading crypto asset exchange with the lowest trading fee.</p>
                                        </article>
                                    </div>
                                    <div className="col-sm-6 col-lg-4 wow fadeIn">
                                        {/* Box Minimal*/}
                                        <article className="box-minimal">
                                            <span class="icon box-minimal__icon linearicons-group-work"></span>
                                            <h4 className="box-minimal__title">Referral and Reward Program</h4>
                                            <div className="box-minimal__divider" />
                                            <p>Get instant bonus rewards and maximize your earnings through crypto from our referral programs.</p>
                                        </article>
                                    </div>
                                    <div className="col-sm-6 col-lg-4 wow fadeIn" data-wow-delay=".1s">
                                        {/* Box Minimal*/}
                                        <article className="box-minimal">
                                            <span class="icon box-minimal__icon linearicons-chart-growth"></span>
                                            <h4 className="box-minimal__title">Trade Anytime, Anywhere</h4>
                                            <div className="box-minimal__divider" />
                                            <p>Download Best In Coins Android and iOS mobile app to buy and sell crypto at any time, from anywhere.</p>
                                        </article>
                                    </div>
                                    <div className="col-sm-6 col-lg-4 wow fadeIn" data-wow-delay=".2s">
                                        {/* Box Minimal*/}
                                        <article className="box-minimal">
                                            <span class="icon box-minimal__icon linearicons-sync"></span>
                                            <h4 className="box-minimal__title">Buy Bitcoin and Crypto Instantly</h4>
                                            <div className="box-minimal__divider" />
                                            <p>Buy and sell crypto assets with comfort at lowest transaction fees and safety.</p>
                                        </article>
                                    </div>
                                    {/* ----- */}
                                    <div className="col-sm-6 col-lg-4 wow fadeIn">
                                        {/* Box Minimal*/}
                                        <article className="box-minimal">
                                            <span class="icon box-minimal__icon linearicons-shield-check"></span>
                                            <h4 className="box-minimal__title">Ultra-Secure Platform</h4>
                                            <div className="box-minimal__divider" />
                                            <p>With utmost security being the basic principle, Best In Coins always fool proof safety standards.</p>
                                        </article>
                                    </div>
                                    <div className="col-sm-6 col-lg-4 wow fadeIn" data-wow-delay=".1s">
                                        {/* Box Minimal*/}
                                        <article className="box-minimal">
                                            <span class="icon box-minimal__icon linearicons-paper-plane"></span>
                                            <h4 className="box-minimal__title">Fast &amp; Robust Technology</h4>
                                            <div className="box-minimal__divider" />
                                            <p>Our super fast trading engine, known for its high scalability, handles multiple real-time orders.</p>
                                        </article>
                                    </div>
                                    <div className="col-sm-6 col-lg-4 wow fadeIn" data-wow-delay=".2s">
                                        {/* Box Minimal*/}
                                        <article className="box-minimal">
                                            <span class="icon box-minimal__icon linearicons-bubble-text"></span>
                                            <h4 className="box-minimal__title">Support That WOWs</h4>
                                            <div className="box-minimal__divider" />
                                            <p>Our 24X7 customer support team assists you  anytime to ensure a smooth trading experience.</p>
                                        </article>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                <section className="section parallax-container section-md bg-gray-700 text-center section-overlay-1 text-lg-left" data-parallax-img="images/-parallax-1.jpg" style={{ padding: '85px 0 85px' }}>
                    <div className="material-parallax parallax"><img src="images/-parallax-1.jpg" alt="" style={{ display: 'block', transform: 'translate3d(-50%, 631px, 0px)' }} /></div>
                    <div className="parallax-content">
                        <div className="container">
                            <div className="row row-30 align-items-center">
                                <div className="col-lg-9 wow fadeInLeftSmall text-left" style={{ visibility: 'visible', animationName: 'fadeInLeftSmall' }}>
                                    <h1 style={{ fontWeight: "400" }}>Buy , Sell & Trade On The Best Crypto Exchange.</h1>
                                    <p className="big">Best In Coins makes trading easy, secure,  fast & reliable. With 24/7 support, Top-level security. </p>
                                </div>
                                <div className="col-lg-3 wow fadeInRightSmall text-lg-right" style={{ visibility: 'visible', animationName: 'fadeInRightSmall' }}>
                                    {this.loginId ?
                                        <a href={`${config.baseUrl}Exchange`} className="button btn-primary text-white">Exchange</a>
                                        : <a href={`${config.baseUrl}signup`} className="button btn-primary text-white">Register now</a>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Footer />
            </>
        )
    }
}