const TradeRules = {
    BTC: 5,
    ETH: 4,
    BNB: 3,
    UNI: 2,
    THETA: 1,
    GRT: 0,
    USDC: 0,
    BUSD: 0,
    LTC: 3,
    LINK: 2,
    MATIC: 1,
    AVAX: 2,
    BCH: 3,
    BTT: 0,
    TRX: 1,
    AXS: 2,
    DOGE: 0,
    CHZ: 0,
    ALGO: 0,
    HOT: 0,
    SHIB: 0,
    ADA: 1,
    MANA: 0,
    GALA: 0,
    XRP: 0,
    SOL: 2,
    FTT: 2,
    DOT: 2,
    SAND: 0,
    XLM: 0,
    PSG: 2,
    LAZIO: 2,
    CITY: 2,
    BAR: 2,
    PORTO: 2,
    SANTOS: 2,
    ATM: 2,
    ACM: 1,
    JUV: 2,
    ASR: 1,
}

export default TradeRules