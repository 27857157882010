const config = {
  
  /*esp server*/

  // socketUrl: "https://espsofttech.org:6010",
  // webSocketUrl:'wss://espsofttech.org:6010/',
  // baseUrl: '/growfinex/',
  // // baseUrl: '/exc/',
  // imageUrl: 'https://espsofttech.org/growfinex/backend/uploads/',
  // apiUrl: "https://espsofttech.org:6010/api",
  // coinImageUrl: 'images/coins/',
  // websiteUrl: 'https://espsofttech.org/growfinex/',
  // HttpOnly: false,
  // Secure:true

  /*local server */

  // socketUrl: "http://localhost:8010",
  // webSocketUrl: 'ws://localhost:8020/',
  // baseUrl: '/exc/',
  // imageUrl: 'https://espsofttech.org/growfinex/backend/uploads/',
  // apiUrl: "http://localhost:8010/api",
  // coinImageUrl: 'images/coins/',
  // websiteUrl: 'http://localhost:3000/',
  // HttpOnly: false,
  // Secure:true


  /*Testing server */

  // socketUrl: "https://fusiongrid.dev:8010",
  // webSocketUrl: 'wss://fusiongrid.dev:8012/',
  // baseUrl: '/exc/',
  // imageUrl: 'https://fusiongrid.dev/backend/uploads/',
  // apiUrl: "https://fusiongrid.dev:8010/api",
  // coinImageUrl: 'images/coins/',
  // websiteUrl: 'https://fusiongrid.dev/',
  // HttpOnly: false,
  // Secure: true

  /*Audit server */

  socketUrl: "http://bic-dev.vh33.nl:8010",
  webSocketUrl: 'ws://bic-dev.vh33.nl:8020/',
  baseUrl: '/',
  imageUrl: 'http://bic-dev.vh33.nl:8010/api/uploads/',
  apiUrl: "http://bic-dev.vh33.nl:8010/api",
  coinImageUrl: 'images/coins/',
  websiteUrl: 'http://bic-dev.vh33.nl/',
  HttpOnly: false,
  Secure:false



  /*live server*/

  // socketUrl: "https://platinx.exchange:8000",
  // webSocketUrl:'wss://platinx.exchange:8004/',
  // baseUrl: '/exc/',
  // imageUrl: 'https://platinx.exchange/backend/uploads/',
  // apiUrl: "https://platinx.exchange:8000/api",
  // coinImageUrl: 'images/coins/',
  // websiteUrl: 'https://platinx.exchange/',
  // HttpOnly: false,
  // Secure:true


};
export default config